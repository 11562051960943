import request from '@wearetla/tla-essentials-tools/utilities/request';
import kebabCase from 'lodash/kebabCase';
import omit from 'lodash/omit';

// Dummy Data
import dummyLocations from '/data/dummy/locations';

const siteServices = {
	getLocations: () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(dummyLocations.map((l => ({
					...l,
					value: l.slug,
					label: l.shortTitle
				}))));
			}, 2)
		});
	},
	getAllBranches: () => {
		return new Promise((resolve, reject) => {
			request.get('client/all', false, (payload, status, request) => {
				if (payload?.client_branches?.length) {
					resolve(payload.client_branches);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getShowcase: (showcaseType, rawFilters) => {
		const filters = Object.keys(rawFilters ?? {}).reduce((newFilters, key) => {
			if(rawFilters[key]) {
				return {
					...newFilters,
					[key]: rawFilters[key],
				}
			}
			else {
				return newFilters;
			}
		}, {});
		return new Promise((resolve, reject) => {
			request.get('event/showcase', { showcase_type: showcaseType, ...(filters ?? {}) }, (payload, status, request) => {
				if (payload?.showcases?.length) {
					resolve(payload.showcases[0]);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getFaqCategories: () => {
		return new Promise((resolve, reject) => {
			request.get('client/faq', false, (payload, status, request) => {
				if(payload?.faqs) {
					resolve(payload.faqs.map(faq => ({
						title: faq.title,
						id: faq.id,
						slug: kebabCase(faq.title),
					})))
				}
				else {
					reject(request);
				}
			});
		});
	},
	getFaqDetail: (faqId) => {
		return new Promise((resolve, reject) => {
			request.get('client/faq', false, (payload, status, request) => {
				if(payload?.faqs) {
					resolve(payload.faqs.find(f => f.id.toString() === faqId));
				}
				else {
					reject(request);
				}
			});
		});
	},
	getPageCategories: () => {
		return new Promise((resolve, reject) => {
			request.get('client/content', false, (payload, status, request) => {
				if(payload?.contents) {
					resolve(payload.contents.map(content => ({
						title: content.title,
						id: content.id,
						slug: kebabCase(content.title),
					})))
				}
				else {
					reject(request);
				}
			});
		});
	},
	getPageDetail: (contentId) => {
		return new Promise((resolve, reject) => {
			request.get('client/content', false, (payload, status, request) => {
				if(payload?.contents) {
					const foundContents = payload.contents.find(f => f.id.toString() === contentId);
					if(foundContents) {
						resolve({
							...omit(foundContents, ['contents']),
							description: foundContents?.description,
						});
					}
					else {
						reject(request);
					}
				}
				else {
					reject(request);
				}
				
			});
		});
	},
	// Contact Form
	getContactSubjects: (branchId) => {
		return new Promise((resolve, reject) => {
			request.get('client/contact/subjects', { branch_id: branchId }, (payload, status, request) => {
				if(status === 200 && payload?.contact_subjects) {
					resolve(payload.contact_subjects.map(subject => ({
						...subject,
						value: subject.key,
						label: subject.value,
					})));
				}
				else {
					reject(request?.data?.meta?.messages || 'Konular getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	submitContactForm: (formData) => {
		return new Promise((resolve, reject) => {
			request.post('client/contact', formData, (payload, status, request) => {
				if(status === 200) {
					resolve(request?.data?.meta?.messages || { success: 'Mesajınız başarıyla gönderildi.' });
				}
				else {
					reject(request?.data?.meta?.messages || 'Mesajınız gönderilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	// Menu
	getAllMenu: () => {
		return new Promise((resolve, reject) => {
			request.get('menu/all-products', false, (payload, status, request) => {
				if(payload?.all_products) {
					resolve(payload.all_products);
				}
				else {
					reject(request);
				}
				
			});
		});
	},
	getBranchMenu: (branchSlug) => {
		return new Promise((resolve, reject) => {
			request.get(`menu/branch/${branchSlug}`, false, (payload, status, request) => {
				if(payload?.menu) {
					resolve({
						...payload.menu,
						merchants: payload.menu?.merchants?.filter?.(merchant => !!merchant.menus.filter(menu => !!menu.products.length).length) ?? [],
					});
				}
				else {
					reject(request);
				}
				
			});
		});
	},
	// Forms
	getCustomForm: (formId) => {
		return new Promise((resolve, reject) => {
			request.get(`form/${formId}`, false, (payload, status, request) => {
				if(status === 200 && payload?.form) {
					resolve(payload.form);
				}
				else {
					reject(request?.data?.meta?.messages || 'Form getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	submitCustomForm: (formId, fields) => {
		return new Promise((resolve, reject) => {
			request.post(`form/${formId}/answer`, fields, (payload, status, request) => {
				if(status === 200) {
					resolve(request?.data?.meta?.messages?.success?.length ? request?.data?.meta?.messages : { success: 'Form başarıyla iletildi, teşekkür ederiz.' });
				}
				else {
					reject(request?.data?.meta?.messages || 'Form gönderilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	resetCustomForm: (formId) => {
		return new Promise((resolve, reject) => {
			request.delete(`form/${formId}/answer`, false, (payload, status, request) => {
				if(status === 200) {
					resolve(request?.data?.meta?.messages?.success?.length ? request?.data?.meta?.messages : { success: 'Form başarıyla iletildi, teşekkür ederiz.' });
				}
				else {
					reject(request?.data?.meta?.messages || 'Form gönderilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	// Amateur Band Form
	submitAmateurBandForm: (fields) => {
		return new Promise((resolve, reject) => {
			request.post(`client/amateur-band-form`, fields, (payload, status, request) => {
				if(status === 200) {
					resolve(request?.data?.meta?.messages || { success: 'Başvurunuz başarıyla iletildi, teşekkür ederiz.' });
				}
				else {
					reject(request?.data?.meta?.messages || 'Başvurunuz gönderilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
}

export default siteServices;