import { useEffect } from "react";

// Context
import { useGlobalState } from "@wearetla/tla-essentials-tools/utilities/global-state";

// Services
import siteServices from '/services/site';

const getLocations = () => {
	return siteServices.getLocations();
}

export const GlobalStateController = () => {
	const { locations, setData } = useGlobalState();

	// CSR fallback.
	useEffect(() => {
		if(!locations) {
			getLocations().then((locations) => {
				setData('locations', locations);
			}).catch(() => {
			})
		}
	}, [locations])

	return null;
}

GlobalStateController.getServerProps = () => {
	return new Promise((resolve) => {
		Promise.all([
			getLocations(),
		]).then(([locations]) => {
			resolve({
				locations,
			})
		}).catch(e => {
			console.error('Initial props error: ', e);
			resolve({
			})
		})
	})
}