export const locations = [
	{
		city: 'Ankara',
		shortTitle: 'Ankara',
		title: 'IF Performance Hall Tunus',
		slug: 'ankara',
		address: 'Tunus Caddesi, 14/A, Kavaklıdere, Çankaya, Ankara',
		phone: '+903124189506',
		email: 'ifperformancehall@gmail.com',
		id: 1,
		location: {
			lat: 39.9116468,
			lng: 32.8560768,
		}
	},
	{
		city: 'İstanbul',
		shortTitle: 'İstanbul',
		title: 'IF Performance Hall Beşiktaş',
		slug: 'istanbul',
		address: 'Cihannüma Mahallesi Has Fırın Caddesi Akmaz Çeşme Sokak no : 26 Beşiktaş / İstanbul',
		phone: '+905465669946',
		email: 'besiktas@ifperformance.com',
		id: 2,
		location: {
			lat: 41.0440483,
			lng: 28.866384,
		}
	},
	{
		city: 'Eskişehir',
		shortTitle: 'Eskişehir',
		title: 'IF Performance Hall Eskişehir',
		slug: 'eskisehir',
		address: 'Cassaba Modern AVM Hoşnudiye Mah. 748. Sok. No: 3/A Tepebaşı',
		phone: '+905392060226',
		email: 'ifperformancehall@gmail.com',
		id: 3,
		location: {
			lat: 39.7843544,
			lng: 30.4953359,
		}
	}
]

export default locations;