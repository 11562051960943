import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';
import request from '@wearetla/tla-essentials-tools/utilities/request';

const bookingServices = {
	getData: () => {
		return new Promise((resolve, reject) => {
			request.get('user/wallet', false, (payload, status, request) => {
				if (status === 200 && payload?.wallet) {
					resolve(payload.wallet);
				}
				else {
					reject(request?.data?.meta?.messages || 'Cüzdan bilgileri getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	getTransactions: () => {
		return new Promise((resolve, reject) => {
			request.get('user/wallet/transactions', false, (payload, status, request) => {
				if (status === 200 && payload?.transactions) {
					resolve(payload.transactions.map((transaction) => ({
						...transaction,
						formattedTimestamp: formatDate(transaction.created_at, 'd MMMM yyyy, HH:mm'),
					})));
				}
				else {
					reject(request?.data?.meta?.messages || 'İşlemler getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	preparePayment: (amount, ticketRedirect) => {
		return new Promise((resolve, reject) => {
			request.post('order/prepare-wallet', {
				amount: amount,
				ticket_redirect: !!ticketRedirect,
			}, (payload, status, request) => {
				if (status === 200 && payload?.order) {
					resolve({ ...payload.order, pay_saved_card: payload.pay_saved_card });
				}
				else {
					reject(request?.data?.meta?.messages || 'Siparişiniz oluşturulurken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	makePayment: (saleId, cardInfo) => {
		return new Promise((resolve, reject) => {
			request.post(`order/payment/${saleId}`, cardInfo, (payload, status, request) => {
				if(status === 200 && payload) {
					if(payload.direct_payment === true) {
						resolve();
					}
					else if(payload?.payment?.gateway || payload?.form_data) {
						try {
							if(payload.form_data) {
								const decodedFormHtml = window.atob(payload.form_data);
								document.open();
								document.write(decodedFormHtml);
								document.close();
	
								resolve(payload);
							}
							else {
								throw new TypeError("No Form Data");
							}
						}
						catch(e) {
							if(payload?.payment?.gateway) {
								const bankForm = document.createElement('form');
								bankForm.setAttribute('method', payload.payment.method ?? 'POST');
								bankForm.setAttribute('action', payload.payment.gateway);
				
								for(const inputName of Object.keys(payload.payment.inputs)) {
									const inputField = document.createElement('input');
									inputField.setAttribute('type', 'hidden');
									inputField.setAttribute('name', inputName);
									inputField.setAttribute('value', payload.payment.inputs[inputName]);
									bankForm.append(inputField);
								}
				
								document.body.appendChild(bankForm);
								bankForm.submit();
							}
							else {
								reject('Ödemeniz yapılırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
							}
						}
					}
					else {
						reject(request?.data?.meta?.messages || 'Ödemeniz yapılırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
					}
				}
				else {
					reject(request?.data?.meta?.messages || 'Ödemeniz yapılırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	generateQr: () => {
		return new Promise((resolve, reject) => {
			request.get(`user/wallet/qr`, false, (payload, status, request) => {
				if (status === 200 && payload?.wallet) {
					resolve(payload.wallet);
				}
				else {
					reject(request?.data?.meta?.messages || 'QR kodu getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	checkQrStatus: (qrCode) => {
		return new Promise((resolve, reject) => {
			request.post(`user/wallet/qr-check`, {
				qr_code: qrCode,
			}, (payload, status, request) => {
				if (status === 200 && (payload?.wallet_order || payload?.wallet_order === null)) {
					resolve(payload?.wallet_order);
				}
				else {
					reject(request?.data?.meta?.messages || 'QR kodu kontrol edilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	getRefundData: () => {
		return new Promise((resolve, reject) => {
			request.get(`user/wallet/refund`, false, (payload, status, request) => {
				if (status === 200 && payload?.refund) {
					resolve({
						...payload,
						refund: {
							...payload.refund,
							total_refund: payload.refund.online_credit_card_refund + payload.refund.offline_refund
						}
					});
				}
				else {
					reject(request?.data?.meta?.messages || 'İade verisi getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	makeRefund: (refundData) => {
		return new Promise((resolve, reject) => {
			request.post(`user/wallet/refund`, refundData, (payload, status, request) => {
				if (status === 200) {
					resolve(request?.data?.meta?.messages ?? { success: 'İadeniz gerçekleşti.' });
				}
				else {
					reject(request?.data?.meta?.messages || 'İadeniz gerçekleştirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
}

export default bookingServices;