import loadable from '@loadable/component';
import PropTypes from 'prop-types';

const SentryProvider = loadable(() => import('./sentry-provider'));

export const LazySentryProvider = ({ children }) => {

	if(process.config.sentry?.dsn) {
		return <SentryProvider>{children}</SentryProvider>
	}
	else {
		return children;
	}
}

LazySentryProvider.propTypes = {
	children: PropTypes.node,
}

export default LazySentryProvider;