import { useEffect } from 'react';

// Context
import { setHeaders } from '@wearetla/tla-essentials-tools/utilities/request';
import { useStorage } from '@wearetla/tla-essentials-tools/utilities/storage';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

export const RequestHeadersController = () => {
	const { clientId, authToken } = useStorage();
	const { oneSignalUserId } = useGlobalState();

	useEffect(() => {
		setHeaders(process.config.defaultHeaders);
	}, [])

	useEffect(() => {
		setHeaders({
			'client-id': clientId,
		})
	}, [clientId])

	useEffect(() => {
		setHeaders({
			'authorization': authToken ? `Bearer ${authToken}` : false,
		})
	}, [authToken])

	useEffect(() => {		
		setHeaders({
			'Push-Id': oneSignalUserId || false,
		})
	}, [oneSignalUserId])

	return null;
}