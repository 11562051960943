import '/assets/styles/partials/loader.scss';
import PropTypes from 'prop-types'
import { Loader } from '@wearetla/tla-essentials-tools/partials/loader';
import classNames from 'classnames';

const StyledLoader = ({ solid, dark, small, light, className, ...props }) => {
	return (
		<Loader
			{...props}
			className={classNames(className, { small, solid, dark, light })} />
	)
}

StyledLoader.propTypes = {
	solid: PropTypes.bool,
	dark: PropTypes.bool,
	small: PropTypes.bool,
	className: PropTypes.string,
}

export default StyledLoader;