export const emptyLocation = {
	city: undefined,
	shortTitle: 'Tüm Şehirler',
	label: 'Tüm Şehirler',
	title: 'Tümü',
	slug: 'tumu',
	address: null,
	phone: null,
	email: null,
	id: false,
	location: null,
	value: false,
};

export default emptyLocation;