import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types'
import { setHeaders } from '@wearetla/tla-essentials-tools/utilities/request'

// Context
import { AuthContext } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useStorage } from '@wearetla/tla-essentials-tools/utilities/storage';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';

// Services
import userServices from '/services/user';

export const AuthProvider = ({ children }) => {
	const { authToken, setData } = useStorage();
	const [initialized, setInitialized] = useState(false);
	const [loggedIn, setLoggedIn] = useState(false);
	const [userData, setUserDataRaw] = useState(null);

	const isAdmin = useMemo(() => userData?.role === 'ADMIN', [userData]);
	
	const { openModal } = useModals();

	// Agreement Check
	// useEffect(() => {
	// 	if(userData?.required_agreements?.length) {
	// 		openModal('agreementUpdate', {
	// 			layer: 2,
	// 			agreements: userData.required_agreements,
	// 		});
	// 	}
	// }, [userData])

	const setUserData = (userData) => {
		if(userData) {
			setLoggedIn(true);
			setUserDataRaw(userData);
		}
		else {
			setLoggedIn(false);
			setUserDataRaw(null)
		}
	}

	const softLogin = (token) => {
		return new Promise((resolve, reject) => {
			setHeaders({
				'authorization': `Bearer ${token}`,
			});
			userServices.getUserData().then((payload) => {
				setUserData(payload.user);
				setInitialized(true);
				setData('authToken', token);
				resolve(payload.user);
			}).catch((e) => {
				setHeaders({
					'authorization': false,
				});
				console.warn('User initialization error:', e);
				reject(e);
			})
		});
	}

	const getUserData = () => {
		return new Promise((resolve, reject) => {
			userServices.getUserData().then((user) => {
				setUserData(user);
				resolve(user);
			}).catch((e) => {
				reject(e);
			})
		})
	}

	const hardLogout = () => {
		setData('authToken', false);
	}

	const logout = () => {
		return new Promise((resolve) => {
			openModal('confirm', {
				message: 'Çıkış yapmak istediğinizden emin misiniz?',
				onConfirm: () => {
					hardLogout();
					resolve();
				}
			});
		})
	}

	useEffect(() => {
		if(!authToken) {
			setHeaders({
				'authorization': false,
			});

			if(loggedIn) {
				setUserData(false);
			}
			else {
				setInitialized(true);
				setUserData(false);
			}
		}
		else if(!loggedIn && authToken) {
			getUserData().then(() => {
				setInitialized(true);
			}).catch((e) => {
				setData('authToken', false);
				console.warn('User auth error:', e);
			})
		}
	}, [authToken, loggedIn])

	return (
		<AuthContext.Provider
			value={{
				initialized,
				loggedIn,
				userData,
				isAdmin,
				getUserData,
				logout,
				hardLogout,
				softLogin,
				setUserData,
			}}>
			{children}
		</AuthContext.Provider>
	)
}

AuthProvider.propTypes = {
	children: PropTypes.node,
}