import { useCallback, useMemo, createContext } from 'react';
import PropTypes from 'prop-types'

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useStorage } from '@wearetla/tla-essentials-tools/utilities/storage';

// Data
import emptyLocation from '/data/empty-location';

export const LocationsContext = createContext({
	selectedLocation: null,
	locations: [],
	changeLocation: () => {}
})

export const LocationsProvider = ({ children }) => {
	const { locations } = useGlobalState();
	const { locationId: rawLocationId, setData } = useStorage();

	const locationId = useMemo(() => rawLocationId ?? false, [rawLocationId]);
	
	const locationOptions = useMemo(() => ([emptyLocation, ...(locations ?? [])]), [locations]);

	const selectedLocation = useMemo(() => locationOptions.find(b => b.id === locationId), [locationId, locationOptions]);

	const changeLocation = useCallback((location) => {
		setData('locationId', location?.id ? location?.id : false);
	}, [])

	const api = useMemo(() => ({
		selectedLocation,
		locations,
		locationOptions,
		emptyLocation,
		changeLocation,
	}), [selectedLocation, locations, locationOptions, changeLocation])

	return (
		<LocationsContext.Provider
			value={api}>
			{children}
		</LocationsContext.Provider>
	)
}

LocationsProvider.propTypes = {
	children: PropTypes.node,
}