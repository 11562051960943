const getCardTypeImage = (type) => {
	const filesMap = {
		'Visa': 'visa',
		'Mastercard' : 'mastercard',
		'American Express' : 'amex',
		'Troy' : 'troy',
	}

	let file = filesMap[type];
	return require('/assets/images/payment/card-logos/' + (file ? file : 'default') + '.svg');
}


export default getCardTypeImage