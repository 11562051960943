import { useEffect, useRef, useState } from "react";

// Utils
import { useEvent } from "@wearetla/tla-essentials-tools/utilities/global-events";

// Hooks
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce';

const getGtmCalendarEvents = (event, calendar, option) => {
	const eventData = {
		item_id: calendar.id,
		item_name: event.title,
		item_category: `${event.type?.name} - ${calendar.event_location} - ${calendar.full_human_date}`,
		// item_variant: `${calendar.event_location} - ${calendar.full_human_date}` + (option ? ` - ${option.title}` : ''),
		...(option?.price ? {
			price: option.price,
		} : {})
	};

	const options = option ? [option] : (calendar?.options ?? [null]);

	return options.map(option => ({
		...eventData,
		...(option ? {
			item_variant: option.title,
			price: option.price,
		} : {}),
	}))
}

const sendGtmEvent = (eventKey, params) => {
	// console.log('DEBUG: GTM Event', {
	// 	event: eventKey, 
	// 	ecommerce: params
	// });
	if(process.config.gtm?.id && window.dataLayer) {
		window.dataLayer.push({
			event: eventKey, 
			ecommerce: params
		});
	}
}

const useListViews = () => {
	const listViewsRef = useRef({})
	const [listViews, setListViews] = useState(listViewsRef.current);
	const debouncedListViews = useDebounce(listViews, 1500);

	const clearListView = () => {
		listViewsRef.current = {};
		setListViews({ ...listViewsRef.current });
	}

	const addListView = (listId, listName, listEvent) => {
		if(listEvent?.calendar) {
			listViewsRef.current[listId] = {
				item_list_id: listId,
				item_list_name: listName,
				items: [
					...(listViewsRef.current[listId]?.items ?? []),
					...getGtmCalendarEvents(listEvent, listEvent.calendar),
				]
			}

			setListViews({ ...listViewsRef.current });
		}
	}

	useEffect(() => {
		for(const listId of Object.keys(debouncedListViews)) {
			sendGtmEvent('view_item_list', {
				...debouncedListViews[listId]
			});
		}

		clearListView();
	}, [debouncedListViews])

	return {
		addListView,
		debouncedListViews,
	}
}

export const GtmEventsController = () => {
	const { addListView } = useListViews();

	const getCalendarPrice = (calendar) => {
		return (calendar?.options ?? []).reduce((cheapest, option) => (!cheapest || cheapest > option.price) ? option.price : cheapest, null)
	}

	const detailViewEvent = ({ event, calendar } = {}) => {
		if(event, calendar) {
			sendGtmEvent('view_item', {
				currency: process.config.mainCurrency.code,
				value: getCalendarPrice(calendar),
				items: [
					...getGtmCalendarEvents(event, calendar),
				] 
			});
		}
	};

	const listViewEvent = ({ listId, listName, items: rawItems } = {}) => {
		const items = (rawItems ?? []).filter(i => !!i?.calendar);
		
		if(items.length) {
			sendGtmEvent('view_item_list', {
				item_list_id: listId,
				item_list_name: listName,
				items: [
					...items.map(item => getGtmCalendarEvents(item, item.calendar)).flat(1),
				] 
			});
		}
	};

	const listItemViewEvent = ({ listId, listName, item } = {}) => {
		addListView(listId, listName, item);
	};

	const makeSearchEvent = ({ term } = {}) => {
		sendGtmEvent('search', {
			search_term: term
		});
	};

	const addToCartEvent = ({ event, options } = {}) => {
		if(event?.calendar && options.length) {
			sendGtmEvent('add_to_cart', {
				currency: process.config.mainCurrency.code,
				value: options.reduce((price, option) => (price + option.price), 0),
				items: options.map((option) => ([
					...getGtmCalendarEvents(event, event.calendar, option)
				])).flat(1)
			});
		}
	};

	const removeFromCartEvent = ({ event, options } = {}) => {
		if(event?.calendar && options.length) {
			sendGtmEvent('remove_from_cart', {
				currency: process.config.mainCurrency.code,
				value: options.reduce((price, option) => (price + option.price), 0),
				items: options.map((option) => ([
					...getGtmCalendarEvents(event, event.calendar, option)
				])).flat(1)
			});
		}
	};

	const beginCheckoutEvent = ({ sale } = {}) => {
		if(sale?.event?.calendar) {
			sendGtmEvent('begin_checkout', {
				currency: process.config.mainCurrency.code,
				value: sale.total_price,
				items: sale.products.map((product) => ([
					...getGtmCalendarEvents(sale.event, sale.event.calendar, product.option)
				])).flat(1)
			});
		}
	};

	const completeCheckoutEvent = ({ sale } = {}) => {

		if(sale?.id) {
			sendGtmEvent('purchase', {
				currency: process.config.mainCurrency.code,
				transaction_id: sale.id.toString(),
				value: sale.total_price,
				items: sale.products.map((product) => ([
					...getGtmCalendarEvents(sale.event, sale.event.calendar, product.option)
				])).flat(1)
			});
		}
	};

	useEvent('detailView', detailViewEvent);
	useEvent('listView', listViewEvent);
	useEvent('listItemView', listItemViewEvent);
	useEvent('makeSearch', makeSearchEvent);
	useEvent('addToCart', addToCartEvent);
	useEvent('removeFromCart', removeFromCartEvent);
	useEvent('beginCheckout', beginCheckoutEvent);
	useEvent('completeCheckout', completeCheckoutEvent);
	return null;
}