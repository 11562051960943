import '/assets/styles/app.scss';
import useServerProps from '@wearetla/tla-essentials-tools/hooks/server-props';
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

// Data
import routes from '/routes'
import layouts from '/views/layouts'
import validations from '/data/validation'

import { RequestHeadersController } from '/controllers/request-headers';
import { GlobalStateController } from '/controllers/global-state';
import { GtmEventsController } from './controllers/analytics';
import { ModalsProvider, ModalsRenderer } from '@wearetla/tla-essentials-tools/utilities/modals';

// Context & Utils
import { NavigatorProvider, NavigatorRoutes } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { WalletProvider } from '/utilities/wallet';
import { AuthProvider } from '/utilities/auth';
import { ApplicationProvider } from '/utilities/application';
import { LocationsProvider } from '/utilities/locations';
import { FormsProvider } from '@wearetla/tla-essentials-tools/partials/forms';
import { StorageProvider } from '@wearetla/tla-essentials-tools/utilities/storage';
import { GlobalStateProvider } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { BreakpointsProvider } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { Messenger } from '@wearetla/tla-essentials-tools/utilities/messenger';
import { HeadProvider } from '@wearetla/tla-essentials-tools/utilities/head'
import { GlobalEventsProvider } from '@wearetla/tla-essentials-tools/utilities/global-events';
import { LazySentryProvider } from '/utilities/sentry';

// Partials
import Loader from '/views/partials/loader';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import customInputTypes from '/views/partials/input-types';

// Modals
import modals from '/views/modals';

// Lazy Sections
const MobileNav = loadable(() => import('/views/sections/mobile-nav'));

const ModalCloseBtn = <div className="modal-closebtn">
	<button className="closebtn-btn">
		<Icon name="close" />
	</button>
</div>;

const App = ({serverProps: ssrProps}) => {
	const serverProps = useServerProps(ssrProps);

	return (
		<LazySentryProvider>
			<ModalsProvider config={{
				layers: 3,
				styles: () => (
					import('/assets/styles/modals/modals.scss')
				),
				modals: modals,
				closeBtn: ModalCloseBtn,
			}}>
				<GlobalStateProvider initialData={serverProps?.sharedData}>
					<GlobalEventsProvider>
						<StorageProvider initialCookies={serverProps?.cookies}>
							<RequestHeadersController />
								<BreakpointsProvider initialState={serverProps?.mediaStatus}>
									<ApplicationProvider initialState={serverProps?.isApplication || serverProps?.mediaStatus?.mobile}>
										<WalletProvider>
											<LocationsProvider>
												<AuthProvider>
													<FormsProvider validations={validations} inputTypes={customInputTypes}>
														<NavigatorProvider
															serverProps={serverProps}
															pageLoader={<Loader />}
															routes={routes}
															layouts={layouts}>
															<HeadProvider serverProps={serverProps}>
																<ModalsRenderer />
																<GtmEventsController />
																<GlobalStateController />
																<Messenger />
																<NavigatorRoutes />
																<MobileNav />
															</HeadProvider>
														</NavigatorProvider>
													</FormsProvider>
												</AuthProvider>
											</LocationsProvider>
										</WalletProvider>
									</ApplicationProvider>
								</BreakpointsProvider>
						</StorageProvider>
					</GlobalEventsProvider>
				</GlobalStateProvider>
			</ModalsProvider>
		</LazySentryProvider>
	)
}

App.propTypes = {
	serverProps: PropTypes.object
}

export default App;