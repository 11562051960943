import loadable from '@loadable/component'

export default {
	sidemenu: loadable(() => import('/views/layouts/sidemenu/layout')),
	base: loadable(() => import('/views/layouts/base')),
	checkout: loadable(() => import('/views/layouts/checkout')),
	simple: loadable(() => import('/views/layouts/simple')),
	raw: loadable(() => import('/views/layouts/raw')),
	account: loadable(() => import('/views/layouts/account')),
	guide: loadable(() => import('/views/layouts/guide')),
}