import loadable from '@loadable/component'

export default {
	home: {
		path: '/',
		exact: true,
		layout: 'base',
		layoutClass: 'home',
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/home')),
	},
	login: {
		path: '/login',
		redirect: {
			key: 'home',
			params: {}
		},
	},
	eventDetail: {
		path: '/etkinlik/:id/:slug/:calendarId?',
		exact: true,
		layout: 'base',
		layoutClass: 'event-detail',
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/event-detail')),
	},
	customForm: {
		path: '/form/:id',
		exact: true,
		layout: 'base',
		layoutClass: 'custom-form',
		component: loadable(() => import('/views/pages/custom-form')),
	},
	payment: {
		path: '/bilet-satin-al/odeme',
		exact: true,
		layout: 'checkout',
		layoutClass: 'checkout-payment',
		checkoutStep: 2,
		layoutProps: {
			requiresLogin: true,
		},
		head: {
			title: 'Ödeme',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/checkout-payment')),
	},
	checkout: {
		path: '/bilet-satin-al/:id?/:calendarId?/:optionId?',
		exact: true,
		layout: 'checkout',
		layoutClass: 'checkout-ticketselection',
		checkoutStep: 1,
		layoutProps: {
			requiresLogin: false,
		},
		head: {
			title: 'Bilet Seçimi',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/checkout-ticketselection')),
	},
	checkoutSummary: {
		path: '/bilet-ozeti/:id',
		exact: true,
		layout: 'checkout',
		layoutClass: 'checkout-summary',
		checkoutStep: 3,
		layoutProps: {
			requiresLogin: true,
		},
		head: {
			title: 'Bilet Özeti',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/checkout-summary')),
	},
	faq: {
		path: '/sikca-sorulan-sorular/:slug?/:id?',
		exact: true,
		layout: 'base',
		layoutClass: 'faq',
		head: {
			title: 'Sıkça Sorulan Sorular',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/faq')),
	},
	page: {
		path: '/if/:slug?/:id?',
		exact: true,
		layout: 'base',
		layoutClass: 'page',
		head: {
			title: 'Sayfalar',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/page')),
	},
	contact: {
		path: '/iletisim/:branch?',
		exact: true,
		layout: 'base',
		layoutClass: 'contact',
		head: {
			title: 'İletişim',
		},
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/contact')),
	},
	events: {
		path: '/etkinlikler',
		exact: true,
		layout: 'base',
		layoutClass: 'events',
		redirect: {
			key: 'eventsWithLocation',
			params: { location: 'tumu' },
		},
	},
	eventsWithLocation: {
		path: '/etkinlikler/:location/:query?',
		exact: true,
		layout: 'base',
		layoutClass: 'events',
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/events')),
	},
	search: {
		path: '/arama/:term/:query?',
		exact: true,
		layout: 'base',
		layoutClass: 'events',
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/search')),
	},
	// merchantMenu: {
	// 	path: '/menu/:merchantId/:groupId?',
	// 	exact: true,
	// 	layout: 'base',
	// 	layoutClass: 'merchant-menu',
	// 	component: loadable(() => import('/views/pages/merchant-menu')),
	// },
	branchMenu: {
		path: '/menu/:slug?',
		exact: true,
		layout: 'base',
		layoutClass: 'branch-menu',
		component: loadable(() => import('/views/pages/branch-menu')),
	},
	superMuzikFest: {
		path: '/super-muzik-fest',
		redirect: {
			key: 'superMuzikFest.about',
			params: {}
		},
		head: {
			title: 'Süper Müzik Fest',
		},
		children: {
			about: {
				path: '/super-muzik-fest/hakkinda',
				exact: true,
				layout: 'guide',
				layoutClass: 'smf-info',
				layoutProps: {
					title: 'Süper Müzik Fest Hakkında',
				},
				head: {
					title: 'Süper Müzik Fest Hakkında',
				},
				component: loadable(() => import('/views/pages/smf/about')),
				menuIcon: 'info',
			},
			map: {
				path: '/super-muzik-fest/alan-krokisi',
				exact: true,
				layout: 'guide',
				layoutClass: 'smf-map',
				layoutProps: {
					title: 'Super Müzik Fest Alan Krokisi',
				},
				head: {
					title: 'Super Müzik Fest Alan Krokisi',
				},
				component: loadable(() => import('/views/pages/smf/map')),
				menuIcon: 'map',
			},
			lineUp: {
				path: '/super-muzik-fest/line-up',
				exact: true,
				layout: 'guide',
				layoutClass: 'smf-lineup',
				layoutProps: {
					title: 'Super Müzik Fest Line Up',
				},
				head: {
					title: 'Super Müzik Fest Line Up',
				},
				component: loadable(() => import('/views/pages/smf/line-up')),
				menuIcon: 'mic',
			},
			ifPayUsage: {
				path: '/super-muzik-fest/if-pay-nasil-kullanilir',
				exact: true,
				layout: 'guide',
				layoutClass: 'smf-if-pay-usage',
				layoutProps: {
					title: 'IF Pay Nasıl Kullanılır?',
				},
				head: {
					title: 'IF Pay Nasıl Kullanılır?',
				},
				component: loadable(() => import('/views/pages/smf/if-pay-usage')),
				menuIcon: 'wallet',
			},
			faq: {
				path: '/super-muzik-fest/sss',
				exact: true,
				layout: 'guide',
				layoutClass: 'smf-faq',
				layoutProps: {
					title: 'Sıkça Sorulan Sorular',
				},
				head: {
					title: 'Sıkça Sorulan Sorular',
				},
				component: loadable(() => import('/views/pages/smf/faq')),
				menuIcon: 'help',
			},
			menu: {
				path: '/super-muzik-fest/yiyecek-icecek-menu',
				exact: true,
				layout: 'guide',
				layoutClass: 'smf-menu',
				layoutProps: {
					title: 'Yiyecek İçecek Menüsü',
				},
				head: {
					title: 'Yiyecek İçecek Menüsü',
				},
				component: loadable(() => import('/views/pages/smf/menu')),
				menuIcon: 'qr',
			},
		},
	},
	mabelMatiz: {
		path: '/mabel-matiz',
		redirect: {
			key: 'mabelMatiz.about',
			params: {}
		},
		head: {
			title: 'Süper Müzik Fest',
		},
		children: {
			about: {
				path: '/mabel-matiz/hakkinda',
				exact: true,
				layout: 'guide',
				layoutClass: 'mabel-matiz-info',
				layoutProps: {
					title: 'Etkinlik Hakkında',
				},
				head: {
					title: 'Mabel Matiz Etkinliği Hakkında',
				},
				component: loadable(() => import('/views/pages/mabel-matiz/about')),
				menuIcon: 'info',
			},
			lineUp: {
				path: '/mabel-matiz/line-up',
				exact: true,
				layout: 'guide',
				layoutClass: 'mabel-matiz-lineup',
				layoutProps: {
					title: 'Line Up',
				},
				head: {
					title: 'Mabel Matiz Etkinliği Line Up',
				},
				component: loadable(() => import('/views/pages/mabel-matiz/line-up')),
				menuIcon: 'mic',
			},
			ifPayUsage: {
				path: '/mabel-matiz/if-pay-nasil-kullanilir',
				exact: true,
				layout: 'guide',
				layoutClass: 'mabel-matiz-if-pay-usage',
				layoutProps: {
					title: 'IF Pay Nasıl Kullanılır?',
				},
				head: {
					title: 'IF Pay Nasıl Kullanılır?',
				},
				component: loadable(() => import('/views/pages/mabel-matiz/if-pay-usage')),
				menuIcon: 'wallet',
			},
			faq: {
				path: '/mabel-matiz/sss',
				exact: true,
				layout: 'guide',
				layoutClass: 'mabel-matiz-faq',
				layoutProps: {
					title: 'Sıkça Sorulan Sorular',
				},
				head: {
					title: 'Sıkça Sorulan Sorular',
				},
				component: loadable(() => import('/views/pages/mabel-matiz/faq')),
				menuIcon: 'help',
			},
			menu: {
				path: '/mabel-matiz/yiyecek-icecek-menu',
				exact: true,
				layout: 'guide',
				layoutClass: 'mabel-matiz-menu',
				layoutProps: {
					title: 'Yiyecek İçecek Menüsü',
				},
				head: {
					title: 'Yiyecek İçecek Menüsü',
				},
				component: loadable(() => import('/views/pages/mabel-matiz/menu')),
				menuIcon: 'qr',
			},
		},
	},
	oktoberFest: {
		path: '/oktoberfest',
		redirect: {
			key: 'oktoberFest.ifPayUsage',
			params: {}
		},
		head: {
			title: 'Oktoberfest',
		},
		children: {
			ifPayUsage: {
				path: '/oktoberfest/if-pay-nasil-kullanilir',
				exact: true,
				layout: 'guide',
				layoutClass: 'oktoberfest-if-pay-usage',
				layoutProps: {
					title: 'Oktoberfest Alışveriş Sistemi Nasıl Kullanılır?',
				},
				head: {
					title: 'IF Pay Nasıl Kullanılır?',
				},
				component: loadable(() => import('/views/pages/oktoberfest/if-pay-usage')),
				menuIcon: 'wallet',
			},
			about: {
				path: '/oktoberfest/hakkinda',
				exact: true,
				layout: 'guide',
				layoutClass: 'oktoberfest-info',
				layoutProps: {
					title: 'Oktoberfest Hakkında & Kurallar',
				},
				head: {
					title: 'Oktobefest Hakkında & Kurallar',
				},
				component: loadable(() => import('/views/pages/oktoberfest/about')),
				menuIcon: 'info',
			},
			map: {
				path: '/oktoberfest/alan-krokisi',
				exact: true,
				layout: 'guide',
				layoutClass: 'oktoberfest-map',
				layoutProps: {
					title: 'Alan Krokisi',
				},
				head: {
					title: 'Oktoberfest Alan Krokisi',
				},
				component: loadable(() => import('/views/pages/oktoberfest/map')),
				menuIcon: 'map',
			},
			lineUp: {
				path: '/oktoberfest/line-up',
				exact: true,
				layout: 'guide',
				layoutClass: 'oktoberfest-lineup',
				layoutProps: {
					title: 'Line Up',
				},
				head: {
					title: 'Oktoberfest Etkinlik Programı',
				},
				component: loadable(() => import('/views/pages/oktoberfest/line-up')),
				menuIcon: 'mic',
			},
			faq: {
				path: '/oktoberfest/sss',
				exact: true,
				layout: 'guide',
				layoutClass: 'oktoberfest-faq',
				layoutProps: {
					title: 'Sıkça Sorulan Sorular',
				},
				head: {
					title: 'Sıkça Sorulan Sorular',
				},
				component: loadable(() => import('/views/pages/oktoberfest/faq')),
				menuIcon: 'help',
			},
			menu: {
				path: '/oktoberfest/yiyecek-icecek-menu',
				exact: true,
				layout: 'guide',
				layoutClass: 'oktoberfest-menu',
				layoutProps: {
					title: 'Yiyecek İçecek Menüsü',
				},
				head: {
					title: 'Yiyecek İçecek Menüsü',
				},
				component: loadable(() => import('/views/pages/oktoberfest/menu')),
				menuIcon: 'qr',
			},
		},
	},
	party90s: {
		path: '/90-lar-partisi',
		redirect: {
			key: 'party90s.about',
			params: {}
		},
		head: {
			title: '90\'lar Partisi',
		},
		children: {
			about: {
				path: '/90-lar-partisi/hakkinda',
				exact: true,
				layout: 'guide',
				layoutClass: 'p90s-about',
				layoutProps: {
					title: '90\'lar Partisi Hakkında',
				},
				head: {
					title: '90\'lar Partisi Hakkında',
				},
				component: loadable(() => import('/views/pages/90s/about')),
				menuIcon: 'info',
			},
			programme: {
				path: '/90-lar-partisi/etkinlik-programi',
				exact: true,
				layout: 'guide',
				layoutClass: 'p90s-programme',
				layoutProps: {
					title: '90\'lar Partisi Etkinlik Programı',
				},
				head: {
					title: '90\'lar Partisi Etkinlik Programı',
				},
				component: loadable(() => import('/views/pages/90s/programme')),
				menuIcon: 'mic',
			}
		},
	},
	ifPayInstructions: {
		path: '/if-pay/nasil-kullanirim',
		exact: true,
		layout: 'base',
		layoutClass: 'if-pay-instructions',
		component: loadable(() => import('/views/pages/if-pay-instructions')),
	},
	amateurBandFormOldRedirect: {
		path: '/amator-grup-basvuru-formu',
		exact: true,
		redirect: {
			key: 'amateurBandForm',
			params: {}
		},
	},
	amateurBandForm: {
		path: '/sahne-senin',
		exact: true,
		layout: 'base',
		layoutClass: 'amateur-band-form',
		component: loadable(() => import('/views/pages/amateur-band-form')),
	},
	account: {
		path: '/hesabim',
		requiresLogin: true,
		redirect: {
			key: 'account.info',
			params: {}
		},
		children: {
			info: {
				path: '/hesabim/bilgilerim',
				exact: true,
				layout: 'account',
				layoutClass: 'account-info',
				requiresLogin: true,
				head: {
					title: 'Üye Bilgilerim',
				},
				component: loadable(() => import('/views/pages/account/info')),
				menuIcon: 'user',
			},
			bookings: {
				path: '/hesabim/biletlerim',
				exact: true,
				layout: 'account',
				layoutClass: 'account-bookings',
				requiresLogin: true,
				head: {
					title: 'Biletlerim',
				},
				component: loadable(() => import('/views/pages/account/bookings')),
				menuIcon: 'ticket',
			},
			wallet: {
				path: '/hesabim/cuzdanim',
				exact: true,
				layout: 'account',
				layoutClass: 'account-wallet',
				requiresLogin: true,
				head: {
					title: 'Cüzdanım',
				},
				component: loadable(() => import('/views/pages/account/wallet')),
				menuIcon: 'wallet',
			},
			addBalance: {
				path: '/hesabim/cuzdanim/bakiye-yukle',
				exact: true,
				layout: 'account',
				layoutClass: 'account-addbalance',
				requiresLogin: true,
				head: {
					title: 'Bakiye Yükle',
				},
				component: loadable(() => import('/views/pages/account/add-balance')),
				hideFromMenu: true,
			},
			merchantOrder: {
				path: '/hesabim/odeme-al',
				exact: true,
				layout: 'account',
				layoutClass: 'account-merchant-order',
				requiresLogin: true,
				hideMobileTitle: true,
				allowedRoles: ['ADMIN', 'MERCHANT_SALESMAN'],
				hideOnWeb: true,
				head: {
					title: 'Ödeme Al',
				},
				component: loadable(() => import('/views/pages/account/merchant-order')),
				menuIcon: 'qr',
			},
			checkBooking: {
				path: '/hesabim/bilet-kontrol',
				exact: true,
				layout: 'account',
				layoutClass: 'account-check-booking',
				requiresLogin: true,
				hideMobileTitle: true,
				allowedRoles: ['ADMIN', 'BRANCH_MANAGER', 'TICKET_CONTROL'],
				hideOnWeb: true,
				head: {
					title: 'Bilet Kontrol',
				},
				component: loadable(() => import('/views/pages/account/check-booking')),
				menuIcon: 'qr',
			},
		}
	},
	locationSelection: {
		path: '/sehir/:slug?',
		exact: true,
		layout: 'base',
		layoutClass: 'location-selection',
		component: loadable(() => import(/* webpackPrefetch: true */ '/views/pages/location-selection')),
	},
	notFound: {
		path: '*',
		layout: 'simple',
		status: 404,
		head: {
			title: 'Sayfa Bulunamadı',
		},
		component: loadable(() => import('/views/pages/not-found')),
	},
	error: {
		path: '*',
		layout: 'simple',
		status: 500,
		head: {
			title: 'Bir Hata İle Karşılaşıldı',
		},
		component: loadable(() => import('/views/pages/error')),
	}
}