const registeredModals = {
	options: () => (import('./options')),
	confirm: () => (import('./confirm')),
	message: () => (import('./message')),
	agreement: () => (import('./agreement')),
	agreementUpdate: () => (import('./agreement-update')),
	auth: () => (import(/* webpackPrefetch: true */'./auth')),
	mobileMenu: () => (import(/* webpackPrefetch: true */'./mobile-menu')),
	search: () => (import(/* webpackPrefetch: true */'./search')),
	otpPin: () => (import('./otp-pin')),
	walletQr: () => (import(/* webpackPrefetch: true */ './wallet-qr')),
	paymentQrReader: () => (import('./payment-qr-reader')),
	bookingQrReader: () => (import('./booking-qr-reader')),
	bookingSmsCheck: () => (import('./booking-sms-check')),
	ticketQr: () => (import('./ticket-qr')),
	notifications: () => (import(/* webpackPrefetch: true */ './notifications')),
	walletRefund: () => (import('./wallet-refund')),
	walletRefundIbanInput: () => (import('./wallet-refund-iban-input')),
	walletHistory: () => (import('./wallet-history')),
	locations: () => (import('./locations')),
}

export default registeredModals;