import request from '@wearetla/tla-essentials-tools/utilities/request';
import formatDate from '@wearetla/tla-essentials-tools/functions/format-date';
import CreditCards from 'creditcards'

// Functions
import getCardTypeImage from '/functions/get-card-type-image'

const userServices = {
	getUserData: () => {
		return new Promise((resolve, reject) => {
			request.get(`user/me`, {}, (payload, status, request) => {
				if (
					status === 200 &&
					payload?.user
				) {
					resolve({
						...payload.user,
						required_agreements: payload.required_agreements ?? [],
					});
				}
				else {
					reject(request);
				}
			});
		});
	},
	getGuestToken: () => {
		return new Promise((resolve, reject) => {
			request.get(`users/init`, {}, (payload, status, request) => {
				if(payload?.guest_token) {
					resolve(payload.guest_token);
				}
				else {
					reject(request);
				}
			});
		});
	},
	logout: () => {
		return new Promise((resolve, reject) => {
			request.delete(`users/logout`, {}, (payload, status, request) => {
				if(status === 200) {
					resolve();
				}
				else {
					reject(request);
				}
			});
		});
	},	
	// User Info
	updateInfo: (userData) => {
		return new Promise((resolve, reject) => {
			request.post(`user/update`, {...userData}, (payload, status, request) => {
				if(status === 200) {
					resolve(request?.data?.meta?.messages || { success: 'Üyelik bilgileriniz başarıyla güncellendi.' });
				}
				else {
					reject(request?.data?.meta?.messages || 'Üyelik bilgileriniz güncellenirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	deleteUser: (token) => {
		return new Promise((resolve, reject) => {
			request.post(`user/delete`, { otp_token: token }, (payload, status, request) => {
				if(status === 200) {
					resolve(request?.data?.meta?.messages || { success: 'Silme talebinizi aldık. 15 gün içinde hesbınız silinecek.' });
				}
				else {
					reject(request?.data?.meta?.messages || 'Üyelik bilgileriniz güncellenirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	// Tickets
	getUserBookings: (mode = 'active', filters) => {
		return new Promise((resolve, reject) => {
			request.get(`user/orders/ticket/${mode}`, filters, (payload, status, request) => {
				if(status === 200 && payload.orders) {
					resolve({
						bookings: payload.orders,
						pagination: request.data.pagination,
					});
				}
				else {
					reject(request?.data?.meta?.messages || 'Sözleşme getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
				}
			});
		});
	},
	getTicketPDF: (booking, ticket) => {
		return new Promise((resolve, reject) => {
			request.get(`order/detail/${booking.id}/ticket-qr/${ticket.qr_code}`, false, (payload, status, request) => {
				if(status === 200 && payload?.file_base64) {
					resolve(payload.file_base64);
				}
				else {
					reject(request?.data?.meta?.messages || 'PDF indirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
				}
			});
		});
	},
	refundTicket: () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, 1000);
		});
	},
	// Agreements
	getAgreement: (key, parameter) => {
		return new Promise((resolve, reject) => {
			request.get(
				`agreement/${key}${parameter ? `/${parameter}`: ''}`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload?.agreement) {
						resolve(payload.agreement);
					}
					else {
						reject(request?.data?.meta?.messages || 'Sözleşme getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	getAllAgreements: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`agreement/all`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload?.agreements) {
						resolve(payload.agreements);
					}
					else {
						reject(request?.data?.meta?.messages || 'Sözleşmeler getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	updateAgreements: (agreements) => {
		return new Promise((resolve, reject) => {
			request.post(
				`user/agreement/update`,
				{ agreements },
				function (payload, status, request) {
					if (status === 200) {
						resolve(payload);
					}
					else {
						reject(request?.data?.meta?.messages || 'Sözleşmeler onaylanırken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	// Notifications
	getNotificationCount: () => {
		return new Promise((resolve, reject) => {
			request.get(`user/notifications/count`, {}, (payload, status, request) => {
				if (
					status === 200 &&
					payload?.notification_count
				) {
					resolve(payload.notification_count);
				}
				else {
					reject(request);
				}
			});
		});
	},
	readAllNotifications: () => {
		return new Promise((resolve, reject) => {
			request.get(`user/notifications/read-all`, {}, (payload, status, request) => {
				if (
					status === 200
				) {
					resolve(true);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getNotifications: () => {
		return new Promise((resolve, reject) => {
			request.get(`user/notifications`, {}, (payload, status, request) => {
				if (
					status === 200 &&
					payload?.notifications
				) {
					resolve(payload.notifications.map((notification) => ({
						...notification,
						formatted_created_at: formatDate(notification.created_at, 'd MMMM yyyy, HH:mm'),
					})));
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Payment
	getSavedCards: () => {
		return new Promise((resolve, reject) => {
			request.get(`user/card`, {}, (payload, status, request) => {
				if (
					status === 200 &&
					payload?.credit_cards
				) {
					resolve(payload.credit_cards.map(card => {
						const bin = card.card_masked_number.split('*')[0];
						const cardType = CreditCards.card.type(bin.replace(/}D/g,''), true);
						return {
							...card,
							value: card.uuid,
							label: card.card_masked_number,
							bin,
							type: cardType,
							typeImage: getCardTypeImage(cardType),
						}
					}));
				}
				else {
					reject(request);
				}
			});
		});
	},
	removeSavedCard: (cardId) => {
		return new Promise((resolve, reject) => {
			request.post(`user/card/delete/${cardId}`, {}, (payload, status, request) => {
				if(status === 200) {
					resolve(request?.data?.meta?.messages || { success: 'Kartınız başarıyla silindi.' });
				}
				else {
					reject(request?.data?.meta?.messages || 'Kartınız silinirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	// OTP
	sendOTP: (phoneNumber, action = 'login') => {
		return new Promise((resolve, reject) => {
			request.post(`otp/send`, {
				phone: phoneNumber,
				token_type: `${action}_token`,
			}, (payload, status, request) => {
				if (status === 200) {
					resolve(true);
				}
				else {
					reject(request?.data?.meta?.messages || 'SMS gönderilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	checkOTP: (phoneNumber, code) => {
		return new Promise((resolve, reject) => {
			request.post(`otp/verify`, {
				phone: phoneNumber,
				otp_code: code,
			}, (payload, status, request) => {
				if (status === 200 && payload?.otp_token) {
					resolve(payload.otp_token);
				}
				else {
					reject(request?.data?.meta?.messages || 'SMS onaylanırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	login: (phoneNumber, otpToken) => {
		return new Promise((resolve, reject) => {
			request.post('auth/login', {
				phone: phoneNumber,
				otp_token: otpToken,
			}, (payload, status, request) => {
				if (status === 200 && payload?.access_token) {
					resolve(payload);
				}
				else {
					reject(request?.data?.meta?.messages || 'Giriş işlemi gerçekleştirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	register: (registerData) => {
		return new Promise((resolve, reject) => {
			request.post('auth/register', {
				...registerData,
			}, (payload, status, request) => {
				if (status === 200 && payload?.access_token) {
					resolve(payload);
				}
				else {
					reject(request?.data?.meta?.messages || 'Üye kayıt işlemi gerçekleştirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
}

export default userServices;