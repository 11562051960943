import { StrictMode, Fragment } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { loadableReady } from '@loadable/component';

import App from '/app'

loadableReady(() => {
	try {
		const OuterWrap = process.config.strictMode === true ? StrictMode : Fragment;
		hydrateRoot(
			document.getElementById('root'),
			<OuterWrap>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</OuterWrap>
		);
	}
	catch(e) {
		console.error('Hydration error:', e);
	}
});
