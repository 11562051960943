import { useState, createContext } from 'react';
import PropTypes from 'prop-types';

// Services
import walletServices from '/services/wallet';

export const WalletContext = createContext({
})

export const WalletProvider = ({ children }) => {
	const [error, setError] = useState(null);
	const [data, setData] = useState(null);
	const [transactions, setTransactions] = useState(null); 

	const getData = () => {
		return new Promise((resolve, reject) => {
			setData(null);
			walletServices.getData().then((payload) => {
				setData(payload);
				resolve(payload);
			}).catch((error) => {
				setError(error);
				reject(error);
			});
		});
	}

	const getTransactions = () => {
		return new Promise((resolve, reject) => {
			setTransactions(null);
			walletServices.getTransactions().then((payload) => {
				const transactions = payload.map((transaction) => {
					const computedFields = (() => {
						if(transaction.type === 'CHARGE') {
							return {
								title: 'Para Yükleme',
							};
						}

						if(transaction.type === 'SPENDING') {
							return {
								title: `Alışveriş: ${transaction.merchant?.title ?? 'Harcama'}`,
								expense: true,
							}
						}

						if(transaction.type === 'REFUND') {
							return {
								title: 'İade',
								total_price: `${transaction.total_price}`,
								expense: true,
							};
						}

						return {
							title: transaction.type,
						}
					})();

					return {
						...transaction,
						...computedFields,
					}
				});
				setTransactions(transactions);
				resolve(transactions);
			}).catch((error) => {
				setError(error);
				reject(error);
			});
		})
	}

	const updateAll = () => {
		return Promise.all([
			getData(),
			getTransactions(),
		])
	}

	return (
		<WalletContext.Provider
			value={{
				error,
				data,
				transactions,
				updateAll,
				getData,
				getTransactions,
			}}>
			{children}
		</WalletContext.Provider>
	)
}

WalletProvider.propTypes = {
	children: PropTypes.node,
}